/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import ContentPageTemplate from "../templates/content-page";
const MDXLayout = ContentPageTemplate;
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Einzelpsychotherapie"), "\n", React.createElement(_components.p, null, "Weil außer Therapeut und Patient keine weitere Person im Raum ist, erlaubt dieses Therapiesetting einen besonders geschützten Rahmen der Behandlung, was bei manchen Problemlagen sinnvoll und erforderlich ist, zudem von vielen Patienten auch so gewünscht wird."), "\n", React.createElement(_components.p, null, "Manche psychotherapeutische Methoden, wie z. B. das EMDR, sind überhaupt nur in einem Einzelsetting möglich."), "\n", React.createElement(_components.p, null, "Die Therapiesitzung dauert gewöhnlich 50 Minuten und erfolgt zumeist einmal pro Woche, wobei der Behandlungsrhythmus stets individuell festgelegt wird."), "\n", React.createElement(_components.p, null, "Die psychotherapeutische Behandlung muss bei der Krankenkasse beantragt werden. Die Formalitäten werden von uns mit Ihnen gemeinsam erledigt, so dass dies in aller Regel keine Hürde ist."));
}
function MDXContent(props = {}) {
  return React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props));
}
export default MDXContent;
